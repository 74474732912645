import React, {Component} from 'react'

import './Public.css'
import ucaLogo from './UCA-Rhombus-Blue.svg'
import Survey from './Survey/Survey'

import AppContext from '../../context/app-context'
import {withTranslation} from 'react-i18next'

export class Public extends Component {

    static contextType = AppContext

    constructor(props) {
        super(props)

        this.langRef = React.createRef()

    }

    handleLangChange = () => {
        this.context.changeLang(this.langRef.current.value)
    }

    render() {
        const t = this.props.t

        return <>
            <header>
                <div className="Container WideContainer Head-Container">

                    <div className="Container Left-Container">
                        <a className="UCA-Link" href="https://www.ucentralasia.org" title="University of Central Asia"
                           target="_blank" rel="noopener noreferrer">
                            <img className="App-Logo" src={ucaLogo} alt="University of Central Asia"/>
                        </a>
                        <h1 className="App-Title">Safe Disclosure</h1>
                    </div>

                    <div className="Container Right-Container">
                        <i className="fa fas fa-language icon-lang"/>
                        <select className="Select Question__Input" ref={this.langRef} onChange={this.handleLangChange}
                                value={this.context.lang}>
                            <option value='en'>English</option>
                            <option value='ru'>Русский</option>
                        </select>
                    </div>

                </div>
            </header>
            <main>
                <div className="Container WideContainer">
                    <h1 className="Form-Title">{t('FORM_TITLE')}</h1>
                    <Survey/>
                </div>
            </main>
            <footer className="WideContainer">

                <div>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy
                    Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </div>
                <div className="Copyright">© University of Central Asia {new Date().getFullYear()}</div>
            </footer>
        </>
    }
}

export default withTranslation()(Public)