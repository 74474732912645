import React, {useContext, useRef} from 'react'

import AppContext from '../context/app-context'
import {Notiflix} from '../components/Notiflix'

import './Auth.css'
import {request} from '../helpers/api'

const AuthPage = () => {

    const usernameRef = useRef(null)
    const passwordRef = useRef(null)
    const submitRef = useRef(null)

    const appContext = useContext(AppContext)

    const handleSubmit = async e => {

        e.preventDefault()

        const login = usernameRef.current.value
        const password = passwordRef.current.value

        submitRef.current.disabled = true

        request(`query SignIn($login: String!, $password: String!) {
                      signIn(login: $login, password: $password) {
                        userId,
                        token,
                        expiresIn
                    }
                }`,
            {login, password}
        ).then(({signIn}) => {
            submitRef.current.disabled = false
            const {token, userId, expiresIn} = signIn
            appContext.signIn(token, userId, expiresIn)
        }).catch(e => {
            submitRef.current.disabled = false
            Notiflix.Report.Failure('Sign In Failure', e.message)
        })

    }

    return <div className="Auth-Page">
        <form className="SignIn-Form" noValidate={true} onSubmit={handleSubmit}>

            <div className="Input-With-Icon">
                <i className="Input-Icon fas fa-user"/>
                <input ref={usernameRef} className="Input" name="username" type="text" placeholder="username"/>
            </div>
            <div className="Input-With-Icon">
                <i className="Input-Icon fas fa-key"/>
                <input ref={passwordRef} className="Input" name="password" type="password" placeholder="password"/>
            </div>
            <button className="Button Button--Submit" ref={submitRef}>
                Sign In
                <i className="fas fa-sign-in-alt" style={{marginLeft: '8px'}}/>
            </button>
        </form>
    </div>

}

export default AuthPage