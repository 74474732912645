import React, {useContext, useEffect, useState} from 'react'
import moment from 'moment'

import {useDisclosureResource} from '../resources/disclosure'
import {useQuestionResource} from '../resources/question'
import {useAnswerResource} from '../resources/answer'
import {downloadSingle} from '../helpers/api'
import AppContext from '../context/app-context'

import './Dashbord.css'

const List = ({disclosures, questions, answers, onViewDisclosure}) => {

    const titles = (disclosure, questionId) => {

        const questionTitle = questions[questionId].title
        const answerValue = disclosure[questionId]

        let answerTitle = answers[`${questionId}-${answerValue}`].title
        if (answerValue === '99')
            answerTitle = `${answerTitle}: ${disclosure[questionId + 'Other']}`

        return {questionTitle, answerTitle}

    }

    const disclosureListTitle = disclosure => {
        const {answerTitle} = titles(disclosure, 'q0')
        return answerTitle
    }

    const fd = date => moment(new Date(+date)).format('llll')

    const handleViewClick = disclosure => {
        onViewDisclosure && onViewDisclosure(disclosure)
    }

    return <div className="Disclosures-List">
        <table className="Table">
            <thead>
            <tr>
                <th width={1}>ID</th>
                <th>Subject</th>
                <th width={1}>Date</th>
                <th width={1}/>
            </tr>
            </thead>
            <tbody>
            {disclosures.map(disclosure => <tr key={disclosure.id}>
                <td className="u-no-wrap">{disclosure.id}</td>
                <td>{disclosureListTitle(disclosure)}</td>
                <td className="u-no-wrap">{fd(disclosure.createdAt)}</td>
                <td className="u-no-wrap TableRow__Action">
                    <button className="IconButton" onClick={handleViewClick.bind(this, disclosure)}>
                        <i className="fas fa-binoculars"/>
                    </button>
                </td>
            </tr>)}
            </tbody>
        </table>
    </div>
}

const Page = () => {

    const [state, setState] = useState({
        disclosures: [],
        questions: {},
        answers: {},
        isLoading: true,
        disclosureToView: null
    })

    const appContext = useContext(AppContext)

    const {loadAllDisclosures} = useDisclosureResource()
    const {loadAllQuestions} = useQuestionResource()
    const {loadAllAnswers} = useAnswerResource()

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search)
        const disclosureParam = urlParams.get('disclosure')

        if (!state.isLoading)
            return

        Promise.all([
            loadAllDisclosures(),
            loadAllQuestions(),
            loadAllAnswers()
        ]).then(([disclosures, questions, answers]) => {

            const questionsMap = {}
            const answersMap = {}

            questions.forEach(question => questionsMap[question.id] = question)
            answers.forEach(answer => answersMap[`${answer.questionId}-${answer.value}`] = answer)

            let disclosureToView = null
            if (disclosureParam) {
                const filtered = disclosures.filter(disclosure => disclosure.id === disclosureParam)
                if (filtered.length)
                    disclosureToView = filtered[0]
            }

            setState({
                ...state,
                disclosures,
                disclosureToView,
                questions: questionsMap,
                answers: answersMap,
                isLoading: false
            })

        })

    })

    const handleViewDisclosure = disclosure => {
        window.history.pushState(null, null, `?disclosure=${disclosure.id}`)
        setState({
            ...state,
            disclosureToView: disclosure
        })
    }

    const pageTitle = state.disclosureToView ? 'Disclosure details' : 'Disclosures'

    return <div className="Dashboard-Page WideContainer">
        <h1 className="">{pageTitle}</h1>
        <div className="Dashboard-Nav">
            <a className="Button" href='/dashboard' style={{
                marginBottom: '32px',
                opacity: state.disclosureToView ? 1 : 0,
                pointerEvents: state.disclosureToView ? 'all' : 'none'
            }}>
                <i className="fas fa-arrow-left" style={{marginRight: '8px'}}/>
                Dashboard
            </a>
            <div>
                <a className="Button" href='/' target="_blank">
                    Form
                    <i className="fas fa-external-link-square-alt" style={{marginLeft: '8px'}}/>
                </a>
                <a href="#" className="Button Button--Submit" type="button" style={{marginLeft: '8px'}} onClick={e => {
                    e.preventDefault()
                    appContext.signOut()
                }}>
                    Sign Out
                    <i className="fas fa-door-open" style={{marginLeft: '8px'}}/>
                </a>
            </div>
        </div>
        {state.isLoading && <div>Loading...</div>}
        {!state.isLoading && !state.disclosureToView &&
        <List disclosures={state.disclosures} questions={state.questions} answers={state.answers}
              onViewDisclosure={handleViewDisclosure}/>
        }
        {!state.isLoading && state.disclosureToView &&
        <Details disclosure={state.disclosureToView} questions={state.questions} answers={state.answers}/>}
    </div>

}

const NO_ANSWER_TITLE = 'n/a'

const Details = ({disclosure, questions, answers}) => {

    const titles = (disclosure, questionId) => {

        const questionTitle = questions[questionId].title
        const questionType = questions[questionId].type
        const answerValue = disclosure[questionId]

        let answerTitle = NO_ANSWER_TITLE

        switch (questionType.trim()) {
            case 'select_one':
            case 'drop_one_other':
            case 'select_one_other':

                if (answerValue)
                    answerTitle = answers[`${questionId}-${answerValue}`].title

                if (answerValue === '99')
                    answerTitle = `${answerTitle}: ${disclosure[questionId + 'Other']}`

                break
            case 'text_field':
            case 'text_area':
                answerTitle = answerValue || NO_ANSWER_TITLE
                break
            default:
                answerTitle = NO_ANSWER_TITLE
        }

        return {questionTitle, answerTitle}

    }

    const fd = date => moment(new Date(+date)).format('llll')

    const Row = ({titles}) => <div className='Disclosure-Detail__Row'>
        <div className="Question-Title">{titles.questionTitle}</div>
        <div className="Answer-Title">{titles.answerTitle}</div>
    </div>

    const IncidentSchedule = ({schedule}) => {

        const {questionTitle} = titles(disclosure, 'q6')

        return <div className='Disclosure-Detail__Row'>
            <div className="Question-Title">{questionTitle}</div>
            <div className="Answer-Title">
                <table className="Table">
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Exact/Approx</th>
                    </tr>
                    </thead>
                    <tbody>
                    {schedule.map(entry => <tr key={entry.id}>
                        <td style={{textAlign: 'center'}}>{entry.date}</td>
                        <td style={{textAlign: 'center'}}>{entry.time}</td>
                        <td style={{textAlign: 'center'}}>{entry.isExact ? 'exact' : 'approx'}</td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    }

    const InvolvedPeople = ({people}) => {

        const {questionTitle} = titles(disclosure, 'q2')

        return <div className='Disclosure-Detail__Row'>
            <div className="Question-Title">{questionTitle}</div>
            <div className="Answer-Title">
                <table className="Table">
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Position</th>
                    </tr>
                    </thead>
                    <tbody>
                    {people.map(entry => <tr key={entry.id}>
                        <td style={{textAlign: 'center'}}>{entry.title}</td>
                        <td style={{textAlign: 'center'}}>{entry.firstName}</td>
                        <td style={{textAlign: 'center'}}>{entry.lastName}</td>
                        <td style={{textAlign: 'center'}}>{entry.position}</td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    }

    const handleDownload = async file => {

        const response = await downloadSingle(file.filename)
        const blob = await response.blob()

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, file.originalname)
        } else {
            let link = document.createElement('a')
            link.download = file.originalname
            link.href = URL.createObjectURL(blob)
            link.click()
            URL.revokeObjectURL(link.href)
        }

    }

    const Files = ({files}) => {

        const {questionTitle} = titles(disclosure, 'q15')

        return <div className='Disclosure-Detail__Row'>
            <div className="Question-Title">{questionTitle}</div>
            <div className="Answer-Title">
                <table className="Table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Size</th>
                        <th width={1}/>
                    </tr>
                    </thead>
                    <tbody>
                    {files.map(entry => <tr key={entry.id}>
                        <td style={{textAlign: 'center'}}>{entry.originalname}</td>
                        <td style={{textAlign: 'center'}}>{entry.size}</td>
                        <td className="u-no-wrap TableRow__Action">
                            <button className="IconButton" onClick={handleDownload.bind(this, entry)}>
                                <i className="fas fa-download"/>
                            </button>
                        </td>
                    </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    }

    return <div className="Disclosure-Details">
        <div className="Disclosure-Details__Date">{fd(new Date(+disclosure.createdAt))}</div>
        <Row titles={titles(disclosure, 'q0')}/>
        <Row titles={titles(disclosure, 'q1')}/>
        <InvolvedPeople people={disclosure.involvedPeople}/>
        <Row titles={titles(disclosure, 'q3')}/>
        <Row titles={titles(disclosure, 'q4')}/>
        <Row titles={titles(disclosure, 'q5')}/>
        <IncidentSchedule schedule={disclosure.incidentSchedule}/>
        <Row titles={titles(disclosure, 'q7')}/>
        <Row titles={titles(disclosure, 'q8')}/>
        <Row titles={titles(disclosure, 'q9')}/>
        <Row titles={titles(disclosure, 'q10')}/>
        <Row titles={titles(disclosure, 'q11')}/>
        <Row titles={titles(disclosure, 'q12')}/>
        <Row titles={titles(disclosure, 'q13')}/>
        <Row titles={titles(disclosure, 'q14')}/>
        <Files files={disclosure.files}/>
        <Row titles={titles(disclosure, 'q16')}/>
        <Row titles={titles(disclosure, 'q17')}/>
        <Row titles={titles(disclosure, 'q18')}/>
        <Row titles={titles(disclosure, 'q19')}/>
        <Row titles={titles(disclosure, 'q20')}/>
        <Row titles={titles(disclosure, 'q21')}/>
        <Row titles={titles(disclosure, 'q22')}/>
    </div>

}


export default Page