import notiflix from 'notiflix-react'

notiflix.Loading.Init({
    svgColor: '#EAD2AC',
    borderRadius: '4px'
})

notiflix.Report.Init({
    borderRadius: '4px',
    svgSize: '50px'
})

notiflix.Notify.Init({
    useIcon: false,
    useGoogleFont: false,
    fontFamily: 'inherit',
    borderRadius: '3px',
    failure: {
        background: '#D22432'
    }
})

export const Notiflix = notiflix


