import React from 'react'

export default React.createContext({

    /*-Language-*/
    lang: 'en',
    changeLanguage: lang => {
    },

    /*-Auth-*/
    userId: null,
    userToken: null,
    userTokenExpiresAt: null,
    signIn: (userToken, userId, expiresIn) => {
    },
    signOut: () => {
    }

})