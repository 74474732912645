import i18n from 'i18next'

import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            lookupQuerystring: 'lang'
        },
        resources: {
            en: {
                translations: {
                    FORM_TITLE: 'Online Form – Safe Disclosure Reporting',
                    ACKNOWLEDGEMENT_LABEL: 'Acknowledgement',
                    ACKNOWLEDGEMENT_TEXT: 'You acknowledge that the information being submitted to be true and' +
                        ' accurate to the best of your knowledge.',
                    FINALIZATION: 'Finalization',
                    REQUIRED_FIELD: 'Required field',
                    UPLOAD_HINT: 'Please upload a document in PDF format with a max size of 10MB.',
                    PREV: 'Prev',
                    NEXT: 'Next',
                    SUBMIT: 'Submit',
                    DETAILS: 'Details',
                    DATE: 'Date',
                    TIME: 'Time',
                    EXACT_OR_APPROX: 'Exact or Approx',
                    EXACT: 'Exact',
                    APPROX: 'Approx',
                    TITLE: 'Title',
                    FIRST_NAME: 'First Name',
                    LAST_NAME: 'Last Name',
                    COMPANY_POSITION: 'Position in Company',
                    SELECT: 'Select',
                    ERROR_INVALID_FORM: 'Please fill out all required fields',
                    THANK_YOU: 'Thank you',
                    SAVED_TITLE: 'Your report has been received',
                    FORM_SAVING: 'Your disclosure is being saved...',
                    OKAY: 'Okay'
                }
            },
            ru: {
                translations: {
                    FORM_TITLE: 'Онлайн форма – Безопасное раскрытие информации',
                    ACKNOWLEDGEMENT_LABEL: 'Подтверждение',
                    ACKNOWLEDGEMENT_TEXT: 'Вы подтверждаете, что, насколько вам известно, предоставляемая информация' +
                        ' является достоверной и точной.',
                    FINALIZATION: 'Завершение',
                    REQUIRED_FIELD: 'Поле, обязательное для заполнения',
                    UPLOAD_HINT: 'Загрузите документ в формате PDF с максимальным размером 10 МБ.',
                    PREV: 'Назад',
                    NEXT: 'Вперёд',
                    SUBMIT: 'Отправить',
                    DETAILS: 'Подробная информация',
                    DATE: 'Дата',
                    TIME: 'Время',
                    EXACT_OR_APPROX: 'Точная или приблизительная дата ',
                    EXACT: 'Точная',
                    APPROX: 'Приблизительная',
                    TITLE: 'Обращение',
                    FIRST_NAME: 'Имя',
                    LAST_NAME: 'Фамилия',
                    COMPANY_POSITION: 'Должность в Университете',
                    SELECT: 'Выбрать',
                    ERROR_INVALID_FORM: 'Пожалуйста, заполните все обязательные поля',
                    THANK_YOU: 'Спасибо',
                    SAVED_TITLE: 'Вашe сообщение получено',
                    FORM_SAVING: 'Сохранение формы...',
                    OKAY: 'Принято'
                }
            }
        },

        load: 'languageOnly',
        fallbackLng: 'en',
        debug: false,

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ','
        },

        react: {
            wait: true
        }

    })

export default i18n