import {useApi} from '../helpers/use-api'

export const useAnswerResource = () => {

    const {request} = useApi()

    const loadAllAnswers = () => request(`
        query {
              answers {
                id
                value
                title
                questionId
            }
        }
        `, {}, true)
        .then(data => data.answers)

    return {
        loadAllAnswers
    }

}