import {useApi} from '../helpers/use-api'

export const useQuestionResource = () => {

    const {request} = useApi()

    const loadAllQuestions = () => request(`
        query{
              questions {
                id
                title
                type
            }
        }
        `, {}, true)
        .then(data => data.questions)

    return {
        loadAllQuestions
    }

}