import {config} from '../config'

export const request = (query, variables = {}, token = '') => {

    const headers = {
        'Content-Type': 'application/json'
    }

    if (token)
        headers['Authorization'] = `Bearer ${token}`

    return fetch(`${config.apiBaseURL}/gql`, {
        method: 'POST',
        body: JSON.stringify({query, variables}),
        headers,
        credentials: 'include'
    }).then(res => {
        if (res.status !== 200 && res.status !== 201)
            return res.json().then(json => {
                const message = json.errors[0].message || 'API Error'
                throw new Error(message)
            })

        return res.json()
    }).then(json => {
        return json.data
    })
}

export const uploadSingle = body => fetch(`${config.apiBaseURL}/upload`, {
    method: 'POST',
    body: body
}).then(res => {
    if (res.status !== 200 && res.status !== 201)
        throw new Error('Failed')
    return res.json()
}).then(json => {
    return json.data
})

export const verifyRecaptchaResponse = response => fetch(`${config.apiBaseURL}/verify-recaptcha-response`, {
    method: 'POST',
    body: JSON.stringify({response}),
    headers: {
        'Content-Type': 'application/json'
    }
}).then(res => {
    if (res.status !== 200 && res.status !== 201)
        throw new Error('Failed')
    return res.json()
}).then(json => {
    return json
})

export const downloadSingle = file => fetch(`${config.apiBaseURL}/download/${file}`)

