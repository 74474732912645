import {useApi} from '../helpers/use-api'

export const useDisclosureResource = () => {

    const {request} = useApi()

    const loadAllDisclosures = () => request(`
        query {
              disclosures {
                id
                q0
                q0Other
                q1
                q3
                q3Other
                q4
                q4Other
                q5
                q5Other
                q7
                q7Other
                q8
                q9
                q10
                q11
                q12
                q13
                q14
                q16
                q16Other
                q17
                q17Other
                q18
                q19
                q20
                q21
                q22
                involvedPeople {
                    id title firstName lastName position
                }
                incidentSchedule {
                    id date time isExact
                }
                files {
                    id originalname size filename
                }
                updatedAt
                createdAt
            }
        }
        `, {}, true)
        .then(data => data.disclosures)

    return {
        loadAllDisclosures
    }

}