import React, {Component} from 'react'
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import {withTranslation} from 'react-i18next'

import {config} from './config'
import {request} from './helpers/api'
import AuthPage from './pages/Auth'
import DashboardPage from './pages/Dashbord'
import Public from './components/Public/Public'
import AppContext from './context/app-context'

import {I18nContext} from 'react-i18next'

import './App.css'

class App extends Component {

    static contextType = I18nContext

    state = {
        isInitialized: false,
        lang: config.defaultLang,
        userId: null,
        userToken: null,
        userTokenExpiresAt: null
    }

    constructor(props) {
        super(props)
        this.state.lang = this.props.i18n.language.split('-')[0]
    }

    componentDidMount() {
        if (!this.state.isInitialized)
            this.initialize()
                .then(({userId, token, expiresIn}) => {
                    this.setState({
                        userId,
                        userToken: token,
                        userTokenExpiresAt: this.genExpiredAtDate(expiresIn),
                        isInitialized: true
                    })
                })
                .catch(e => {
                    this.setState({
                        userId: null,
                        userToken: null,
                        userTokenExpiresAt: null,
                        isInitialized: true
                    })
                })
    }

    initialize = async () => {

        const timeToRefreshToken = (!this.state.userToken && !this.state.userTokenExpiresAt) || this.state.userTokenExpiresAt < new Date()

        if (timeToRefreshToken) {
            let responseData = await request(`query{refreshToken{userId token expiresIn}}`)
            return responseData.refreshToken
        }

    }

    signIn = (userToken, userId, expiresIn) => {
        this.setState({userToken, userId, userTokenExpiresAt: this.genExpiredAtDate(expiresIn)})
    }

    signOut = () => {
        this.setState({
            userToken: null,
            userId: null,
            userTokenExpiresAt: null
        })
    }

    changeLang = lang => {
        window.history.pushState(null, null, `?lang=${lang}`)
        this.props.i18n.changeLanguage(lang)
        this.setState({lang})
    }

    genExpiredAtDate = expiresIn => {
        const expiresAt = new Date()
        expiresAt.setSeconds(expiresAt.getSeconds() + +expiresIn)
        return expiresAt
    }

    render() {

        if (!this.state.isInitialized)
            return <div>Loading...</div>

        return <BrowserRouter>
            <AppContext.Provider value={{
                userId: this.state.userId,
                userToken: this.state.userToken,
                userTokenExpiresAt: this.state.userTokenExpiresAt,
                lang: this.state.lang,
                signIn: this.signIn,
                signOut: this.signOut,
                changeLang: this.changeLang
            }}>
                <Switch>
                    {/*<Route path='/admin'>Admin</Route>*/}
                    <Route path='/' component={Public} exact/>
                    {this.state.userId && <Redirect from='/auth' to='/dashboard' exact/>}
                    {!this.state.userId && <Redirect from='/dashboard' to='/auth' exact/>}
                    {this.state.userId && <Route path='/dashboard' component={DashboardPage} exact/>}
                    {!this.state.userId && <Route path='/auth' component={AuthPage} exact/>}
                    <Route>404</Route>
                </Switch>

            </AppContext.Provider>
        </BrowserRouter>
    }
}


export default withTranslation()(App)
