import React, {Component, useState, useRef, useEffect, useContext} from 'react'
import {withTranslation, useTranslation} from 'react-i18next'
import ReactGA from 'react-ga'

import {config} from '../../../config'
import {request, uploadSingle, verifyRecaptchaResponse} from '../../../helpers/api'

import Spinner from '../../Spinner/Spinner'
import {Notiflix} from '../../Notiflix'
import AppContext from '../../../context/app-context'

import './Survey.css'

const QuestionLabel = ({question}) => <>
    <label className="Question__Label" htmlFor={question.id}>
        {question.title}
        {question.isRequired && <span className="Required-Mark">*</span>}
    </label>
    {question.description && <p className="Question__Desc">{question.description}</p>}
</>

const TextFieldQuestion = ({question, onQuestionAnswer}) => {

    const handleChange = e => onQuestionAnswer(question, e.target.value)

    const classNames = ['Question']

    if (question.isInvalid)
        classNames.push('Question-Invalid')

    return <div className={classNames.join(' ')}>
        <QuestionLabel question={question}/>
        <input id={question.id} type="text" value={question.value} onChange={handleChange} className="Question__Input"/>
    </div>
}

const TextAreaQuestion = ({question, onQuestionAnswer}) => {

    const handleChange = e => onQuestionAnswer(question, e.target.value)

    if (question.value === undefined)
        question.value = ''

    const classNames = ['Question']

    if (question.isInvalid)
        classNames.push('Question-Invalid')

    return <div className={classNames.join(' ')}>
        <QuestionLabel question={question}/>
        <textarea id={question.id} onChange={handleChange} value={question.value} className="Question__Input"/>
    </div>
}

const DropDownQuestion = ({question, onQuestionAnswer, useOther = false}) => {

    const [t] = useTranslation()

    const selectEl = useRef(null)
    const otherEl = useRef(null)
    const handleChange = e => {

        const value = e.target.value
        const otherValue = otherEl.current ? otherEl.current.value : ''

        if (useOther)
            if (e.target.value === '99') {
                otherEl.current.classList.add('animated')
                otherEl.current.classList.add('fadeIn')
                otherEl.current.focus()
            }

        selectEl.current.dataset.value = value

        onQuestionAnswer(question, value, otherValue)
    }

    const handleOtherChange = () => {
        onQuestionAnswer(question, '99', otherEl.current.value)
    }

    const classNames = ['Question']

    useEffect(() => {
        selectEl.current.dataset.value = ''
    }, [])

    if (question.isInvalid)
        classNames.push('Question-Invalid')

    return <div className={classNames.join(' ')}>
        <QuestionLabel question={question}/>
        <select id={question.id} onChange={handleChange} className="Question__Input" ref={selectEl}>
            <option value="">{t('SELECT')}...</option>
            {question.answers.map(answer => <option key={answer.id} value={answer.value}>{answer.title}</option>)}
        </select>
        {useOther &&
        <input ref={otherEl} type="text" style={{marginTop: '8px', opacity: 0}} className="Question__DropDown__Other"
               onChange={handleOtherChange} placeholder="Specify other here"/>}
    </div>
}

const SelectOneQuestion = ({question, onQuestionAnswer, useOther = false}) => {

    const otherEl = useRef(null)

    const classNames = ['Question']

    if (question.isInvalid)
        classNames.push('Question-Invalid')

    const handleChange = e => {

        const otherValue = otherEl.current ? otherEl.current.value : ''

        if (useOther && e.target.value === '99')
            otherEl.current.focus()

        onQuestionAnswer(question, e.target.value, otherValue)

    }

    const handleOtherChange = () => {
        onQuestionAnswer(question, '99', otherEl.current.value)
    }

    return <div className={classNames.join(' ')}>
        <QuestionLabel question={question}/>
        <div className="Question__Input Question__Radio-Input">
            {question.answers.map(answer => <div className="Radio-Input" key={answer.id}>
                <input id={answer.id} type="radio" name={question.id} value={answer.value} onChange={handleChange}
                       checked={question.value === answer.value}/>
                <label htmlFor={answer.id}>{answer.title}</label>
            </div>)}
            {useOther &&
            <input ref={otherEl} type="text" className="Question__SelectOne__Other" onChange={handleOtherChange}/>}
        </div>

    </div>
}


class InvolvedPerson {
    title = ''
    firstName = ''
    lastName = ''
    position = ''
}

const InvolvedPeopleQuestion = ({question, onQuestionAnswer}) => {

    const [t] = useTranslation()

    let [people, setPeople] = useState([new InvolvedPerson()])

    const handleAdd = () => setPeople([...people, new InvolvedPerson()])

    const handleChange = (person, e) => {
        person[e.target.name] = e.target.value
        onQuestionAnswer(question, people)
    }

    const classNames = ['Question']

    if (question.isInvalid)
        classNames.push('Question-Invalid')

    return <div className={classNames.join(' ')}>
        <QuestionLabel question={question}/>
        <div className="Question__Input Question__Radio-Input">
            <table>
                <thead>
                <tr>
                    <th/>
                    <th>{t('TITLE')}</th>
                    <th>{t('FIRST_NAME')}</th>
                    <th>{t('LAST_NAME')}</th>
                    <th>{t('COMPANY_POSITION')}</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {people.map((person, index) => <tr key={index}>
                    <td>{index + 1}</td>
                    <td><input type="text" name="title" onChange={handleChange.bind(this, person)}
                               value={person.title}/></td>
                    <td><input type="text" name="firstName" onChange={handleChange.bind(this, person)}
                               value={person.firstName}/></td>
                    <td><input type="text" name="lastName" onChange={handleChange.bind(this, person)}
                               value={person.lastName}/></td>
                    <td><input type="text" name="position" onChange={handleChange.bind(this, person)}
                               value={person.position}/></td>
                    <td>{index === people.length - 1 &&
                    <button type="button" className="Button Button__Add" onClick={handleAdd}>
                        <i className="fas fa-user-plus"/>
                    </button>}</td>
                </tr>)}
                </tbody>
            </table>
        </div>
    </div>
}

class IncidentDate {
    date = ''
    time = ''
    isExact = ''
}

const IncidentScheduleQuestion = ({question, onQuestionAnswer}) => {

    const [t] = useTranslation()

    let [schedule, setSchedule] = useState([new IncidentDate()])

    const handleAdd = () => setSchedule([...schedule, new IncidentDate()])

    const handleChange = (date, e) => {
        date[e.target.name] = e.target.value
        onQuestionAnswer(question, schedule)
    }

    const classNames = ['Question']

    if (question.isInvalid)
        classNames.push('Question-Invalid')

    return <div className={classNames.join(' ')}>
        <QuestionLabel question={question}/>
        <div className="Question__Input Question__Radio-Input">
            <table>
                <thead>
                <tr>
                    <th/>
                    <th>{t('DATE')}</th>
                    <th>{t('TIME')}</th>
                    <th>{t('EXACT_OR_APPROX')}</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {schedule.map((date, index) => <tr key={index}>
                    <td>{t('DETAILS')}</td>
                    <td><input type="text" name="date" value={date.date} onChange={handleChange.bind(this, date)}/></td>
                    <td><input type="text" name="time" value={date.time} onChange={handleChange.bind(this, date)}/></td>
                    <td>
                        <div className="Question__ExactApprox">
                            <label><input type="radio" name="isExact" value="true"
                                          onChange={handleChange.bind(this, date)}/> {t('EXACT')}</label>
                            <label><input type="radio" name="isExact" value="false"
                                          onChange={handleChange.bind(this, date)}/> {t('APPROX')}</label>
                        </div>
                    </td>
                    <td>{index === schedule.length - 1 &&
                    <button type="button" className="Button Button__Add" onClick={handleAdd}>
                        <i className="fas fa-calendar-plus"/>
                    </button>}</td>
                </tr>)}
                </tbody>
            </table>
        </div>
    </div>
}

class Document {
    data = ''
}

const FileInput = ({onChange}) => {

    const [t] = useTranslation()

    const rootEl = useRef(null)

    const handleChange = e => {

        const file = e.target.files[0]
        const isValidFile = file.type === 'application/pdf' && file.size < 10000000

        rootEl.current.classList.toggle('isInvalid', !isValidFile)

        if (isValidFile)
            onChange && onChange(file)
        else
            e.target.value = null

    }

    return <div ref={rootEl} className="FileInput">
        <input type="file" onChange={handleChange} accept="application/pdf"/>
        <div className="Question-Hint">{t('UPLOAD_HINT')}</div>
    </div>

}

const DocumentsQuestion = ({question, onQuestionAnswer}) => {

    let [documents, setFiles] = useState([new Document()])

    const handleAdd = () => setFiles([...documents, new Document()])

    const handleChange = (document, file) => {
        document.data = file
        onQuestionAnswer(question, documents)
    }

    return <div className="Question">
        <QuestionLabel question={question}/>
        {documents.map((document, index) => <div key={index} className="Question-File">
            <FileInput onChange={handleChange.bind(this, document)}/>
            {index === documents.length - 1 &&
            <button type="button" className="Button Button__Add" onClick={handleAdd}>+</button>}
        </div>)}
    </div>
}


const useI18N = () => {
    const appContext =  useContext(AppContext)
    return resource => i18n(resource, appContext.lang)
}

const QuestionFactory = ({question, onQuestionAnswer}) => {

    const tR = useI18N()

    const tQuestion = tR(question)
    tQuestion.answers = question.answers.map(tR)

    switch (question.type.trim()) {
        case 'file_multi':
            return <DocumentsQuestion question={tQuestion} onQuestionAnswer={onQuestionAnswer}/>
        case 'custom_date':
            return <IncidentScheduleQuestion question={tQuestion} onQuestionAnswer={onQuestionAnswer}/>
        case 'person_multi':
            return <InvolvedPeopleQuestion question={tQuestion} onQuestionAnswer={onQuestionAnswer}/>
        case 'drop_one_other':
            return <DropDownQuestion question={tQuestion} onQuestionAnswer={onQuestionAnswer} useOther={true}/>
        case 'select_one':
            return <SelectOneQuestion question={tQuestion} onQuestionAnswer={onQuestionAnswer}/>
        case 'select_one_other':
            return <SelectOneQuestion question={tQuestion} onQuestionAnswer={onQuestionAnswer} useOther={true}/>
        case 'text_field':
            return <TextFieldQuestion question={tQuestion} onQuestionAnswer={onQuestionAnswer}/>
        case 'text_area':
            return <TextAreaQuestion question={tQuestion} onQuestionAnswer={onQuestionAnswer}/>
        default:
            console.log(tQuestion.type)
            return ''
    }
}

const Group = ({group, index, onGroupSubmit, onPrevGroup, className}) => {

    const tR = useI18N()
    const [t] = useTranslation()

    const [state, setState] = useState({
        isFormSubmitted: false,
        isFormValid: false,
        values: {},
        questions: group.questions
    })

    useEffect(() => {
        if (state.isFormSubmitted && state.isFormValid) {
            onGroupSubmit({...group, questions: [...state.questions]})
            setState({...state, isFormSubmitted: false})
        }
    }, [state.isFormValid, state.isFormSubmitted, group, onGroupSubmit, state])

    const handleSubmit = e => {
        e.preventDefault()

        const validatedQuestions = validateQuestions(state.questions)
        const isFormValid = validatedQuestions.filter(question => question.isInvalid).length === 0

        if (!isFormValid)
            Notiflix.Notify.Failure(t('ERROR_INVALID_FORM'))

        setState({
            ...state,
            isFormSubmitted: true,
            isFormValid,
            questions: validatedQuestions
        })

    }

    const validateQuestions = questions => {
        let updatedQuestions = [...questions]

        for (const question of updatedQuestions) {
            question.isInvalid = question.isRequired && !question.value
        }

        return updatedQuestions
    }

    const handleQuestionAnswer = (question, value, other = '') => {

        const updatedQuestions = [...state.questions]

        let questionIndex = -1
        for (let i in state.questions)
            if (state.questions[i].id === question.id) {
                questionIndex = i
                break
            }

        updatedQuestions[questionIndex] = {
            ...question,
            value,
            valueOther: other
        }

        setState({...state, questions: updatedQuestions})

    }

    const tGroup = tR(group)

    return <div className={'Group ' + className}>
        <form noValidate onSubmit={handleSubmit}>
            <h1 className="Group__Title">{index + 1}) {tGroup.title}</h1>
            {tGroup.description && <p className="Group__Desc">{tGroup.description}</p>}
            {state.questions.map(question => <QuestionFactory key={question.id} question={question}
                                                              onQuestionAnswer={handleQuestionAnswer}/>)}
            <div className="Group__Hint">
                <span className="Required-Mark">*</span> {t('REQUIRED_FIELD')}
            </div>
            <div className="Group__Controls">
                <button className="Button" type="button" onClick={onPrevGroup}>
                    <i className="fas fa-arrow-left" style={{marginRight: '8px'}}/>
                    {t('PREV')}
                </button>
                <button className="Button">
                    {t('NEXT')}
                    <i className="fas fa-arrow-right" style={{marginLeft: '8px'}}/>
                </button>
            </div>
        </form>
    </div>

}

const i18n = (entry, lang = 'en') => {

    const clone = {...entry}

    if (lang === 'en' || !entry.i18n)
        return clone

    const translations = JSON.parse(entry.i18n)[lang]

    if (!translations)
        return clone

    return {...clone, ...translations}

}

const initialState = {
    isLoading: true,
    isSaving: false,
    groups: [],
    activeGroup: 0,
    questions: {}
}

class Survey extends Component {

    state = initialState

    constructor(props) {
        super(props)
        this.acknowledgementRef = React.createRef()
        ReactGA.initialize(config.gaSiteKey)
        const url = window.location.pathname + window.location.search
        ReactGA.pageview(url)
    }

    componentDidMount() {
        this.fetchGroups()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.isSaving)
            this.save().then(() => {
                Notiflix.Report.Success(this.props.t('THANK_YOU'), this.props.t('SAVED_TITLE'), this.props.t('OKAY'), () => {
                    window.location.reload()
                })
            })

    }

    fetchGroups() {

        this.setState({isLoading: true})

        request(`query {
                    groups {
                        id
                        title
                        description
                        i18n
                        questions {
                            id
                            type
                            title
                            isRequired
                            description
                            i18n
                            answers {
                                id
                                value
                                title
                                description
                                i18n
                            }
                        }
                    }
                }`
        ).then(data => {
            this.setState({
                isLoading: false,
                groups: data.groups
            })
        })

    }

    get isLastGroup() {
        return this.state.activeGroup === this.state.groups.length
    }

    async save() {

        Notiflix.Loading.Hourglass(this.props.t('FORM_SAVING'))

        const allValues = {}

        this.state.groups.forEach(group => {
            group.questions.forEach(question => {

                if (question.value)
                    allValues[question.id] = question.value

                if ((question.type === 'select_one_other' || question.type === 'drop_one_other') && question.valueOther)
                    allValues[`${question.id}Other`] = question.valueOther

            })
        })

        const {q2, q6, q15, ...values} = allValues

        const disclosureId = await this.saveDisclosure(values)


        if (q2)
            for (let values of q2)
                await this.saveInvolvedPerson(disclosureId, values)

        if (q6)
            for (let values of q6)
                await this.saveIncidentSchedule(disclosureId, {...values, isExact: values.isExact === 'true'})


        if (q15) {
            Notiflix.Loading.Change('Files uploading...')
            for (let values of q15) {
                const data = new FormData()
                data.append('file', values.data)
                data.append('name', values.data.name)
                data.append('size', values.data.size)
                data.append('type', values.data.type)
                data.append('lastModified', values.data.lastModified)
                data.append('disclosureId', disclosureId)
                await uploadSingle(data)
            }
        }

        Notiflix.Loading.Remove()

        return Promise.resolve()

    }

    async saveDisclosure(input) {
        return request(`
            mutation ReportDisclosure($input: DisclosureInput!) {
                reportDisclosure(input: $input) {
                    id
                }
            }`,
            {input}
        ).then(data => data.reportDisclosure.id)
    }

    async saveInvolvedPerson(disclosureId, input) {
        return request(`
            mutation AddInvolvedPerson($disclosureId: ID!, $input: InvolvedPersonInput!) {
                addInvolvedPerson(disclosureId: $disclosureId, input: $input) {
                    id
                }
            }`,
            {disclosureId, input}
        ).then(data => data.addInvolvedPerson.id)
    }

    async saveIncidentSchedule(disclosureId, input) {
        return request(`
            mutation AddIncidentSchedule($disclosureId: ID!, $input: IncidentScheduleInput!) {
                addIncidentSchedule(disclosureId: $disclosureId, input: $input) {
                    id
                }
            }`,
            {disclosureId, input}
        ).then(data => data.addIncidentSchedule.id)
    }

    handleGroupSubmit = group => {

        let groupIndex = -1

        for (let i in this.state.groups)
            if (group.id === this.state.groups[i].id) {
                groupIndex = i
                break
            }

        const updatedGroups = [...this.state.groups]
        updatedGroups[groupIndex] = group

        if (this.state.activeGroup < this.state.groups.length) {
            window.scroll(0, 0)
            return this.setState({
                activeGroup: +groupIndex + 1,
                groups: updatedGroups
            })
        }

    }

    handlePrevGroup = () => {
        if (this.state.activeGroup >= 1)
            this.setState({activeGroup: +this.state.activeGroup - 1})
    }

    handleSubmit = async () => {

        const isChecked = this.acknowledgementRef.current.checked

        if (!isChecked)
            return Notiflix.Notify.Failure('Please confirm the acknowledge clause and press Submit.')

        const isHuman = await this.isHumanOverHere()

        if (isHuman)
            this.setState({
                isSaving: true
            })

    }

    async isHumanOverHere() {

        const action = 'submitform'

        const gResponse = await window.grecaptcha.execute(config.gRecaptchaSiteKey, {action})
        const response = await verifyRecaptchaResponse(gResponse)

        return response.success && response.action === action && response.score >= 0.5

    }

    render() {

        const {t} = this.props

        if (this.state.isLoading)
            return <Spinner/>

        return <div className="Survey">
            <div className="Survey__Progress">
                {this.state.groups.map((group, index) => <div key={group.id} className={'Survey__Progress-Indicator' +
                (index < this.state.activeGroup ? ' isSuccess' : (index === this.state.activeGroup ? ' isActive' : ''))
                }/>)}
                <div
                    className={'Survey__Progress-Indicator' + (this.state.activeGroup === this.state.groups.length ? ' isActive' : '')}/>
            </div>
            {this.state.groups.map((group, index) => <Group key={group.id}
                                                            className={index === this.state.activeGroup ? 'isActive' : 'isInactive'}
                                                            group={group} index={index}
                                                            onGroupSubmit={this.handleGroupSubmit}
                                                            onPrevGroup={this.handlePrevGroup}/>)}

            {this.isLastGroup && <div className="Group">
                <h1 className="Group__Title">{this.state.groups.length + 1}) {t('FINALIZATION')}</h1>
                <div className="Question Question__Acknowledgment">
                    <label className="Question__Label">{t('ACKNOWLEDGEMENT_LABEL')}<span
                        className="Required-Mark">*</span></label>
                    <div className="Question__Input Question__Radio-Input">
                        <div className="Checkbox-Input">
                            <input id="a_acknowledgment" type="checkbox" ref={this.acknowledgementRef}/>
                            <label htmlFor="a_acknowledgment">{t('ACKNOWLEDGEMENT_TEXT')}</label>
                        </div>
                    </div>
                </div>

                <div id="inline-badge"/>

                <div className="Group__Controls">
                    <button className="Button" type="button" style={{}} onClick={this.handlePrevGroup}>
                        <i className="fas fa-arrow-left" style={{marginRight: '8px'}}/>
                        {t('PREV')}
                    </button>
                    <button className="Button Button--Submit" type="button" onClick={this.handleSubmit}>
                        {t('SUBMIT')}
                        <i className="fas fa-clipboard-check" style={{marginLeft: '8px'}}/>
                    </button>
                </div>

            </div>}

        </div>

    }
}

export default withTranslation()(Survey)