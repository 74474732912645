import {useContext} from 'react'

import {request as apiRequest} from './api'
import AppContext from '../context/app-context'

export const useApi = () => {

    const appContext = useContext(AppContext)

    const request = (query, variables = {}, useToken = false) => {
        const token = useToken ? appContext.userToken : ''
        return apiRequest(query, variables, token).catch(e => {
                appContext.signOut()
            }
        )
    }

    return {
        request
    }

}